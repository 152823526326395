<template>
  <div class="">
    <div class="flex items-center justify-between mb-4">
      <div
        v-if="showFlexible"
        class="flex items-center justify-between w-[220px] mx-auto rounded-lg bg-f-tertiary/[.07] hj:bg-f-white shadow-inner p-1">
        <div
          class="grow py-1 rounded-md cursor-pointer"
          :class="{
            'bg-f-bg text-f-white': mode === modes.date.value,
            'bg-transparent hj:bg-f-white text-gray-500': mode === modes.flexible.value,
            'px-2': isSmallMode,
            'px-4': isLargeMode,
          }"
          @click="handleMode(modes.date)">
          <p class="text-xs text-center">Choose date</p>
        </div>
        <div
          class="grow py-1 rounded-md cursor-pointer"
          :class="{
            'bg-f-bg text-f-white': mode === modes.flexible.value,
            'bg-transparent hj:bg-f-white text-gray-500': mode === modes.date.value,
            'px-2': isSmallMode,
            'px-4': isLargeMode,
          }"
          @click="handleMode(modes.flexible)">
          <p class="text-xs text-center">I'm flexible</p>
        </div>
      </div>
      <div>
        <button
          type="button"
          class="rounded-xl bg-transparent border border-2 font-bold border-f-contrast py-1 text-f-contrast hj:uppercase hj:font-bold hover:hj:bg-f-primary hover:hj:text-f-white hover:hj:border-f-primary"
          :class="{ 'px-4 text-sm ml-2': isSmallMode, 'px-6': isLargeMode }"
          @click="$emit('close-picker')">
          Done
        </button>
      </div>
    </div>
    <div
      v-if="mode === modes.date.value"
      class="flex items-center justify-between"
      :class="{ 'widget flex-col': isSmallMode, 'flex-col md:flex-row': isLargeMode }">
      <div class="flex items-center justify-center w-full">
        <Datepicker
          ref="picker"
          :dark="darkMode || $page.props.brand.code === 'hj'"
          class="md:pr-5"
          v-model="selectedDate"
          :minDate="minDate"
          :maxDate="maxDate"
          :disabledDates="disabledDates"
          :disabledWeekDays="disabledWeekdays"
          :start-date="startDate"
          :enableTimePicker="false"
          :autoApply="true"
          :month-change-on-scroll="false"
          inline
          @update:modelValue="handleDate"></Datepicker>
      </div>
      <div v-if="showTime" class="w-[2px] bg-f-bg hj:bg-f-white/50 h-full"></div>
      <div
        v-if="showTime"
        class="border-t border-t-f-bg/60 hj:border-t-white/50"
        :class="{
          'w-full pt-3': isSmallMode,
          'w-full md:w-auto pt-5 md:pt-0 md:border-t-0 md:border-l md:border-l-f-bg/60 md:hj:border-l-white/50 md:pl-5':
            isLargeMode,
        }">
        <TimeCarousel v-if="(isLargeMode && notIsWidget) || (isLargeMode && isWidget && !isMobile)" :items="15">
          <div
            v-for="time in times"
            class="text-center py-1.5 px-6 rounded-lg hover:text-f-tertiary hover:hj:text-f-white hover:bg-f-tertiary/[.07] hover:hj:bg-f-white/10 cursor-pointer"
            :class="{
              'bg-f-secondary text-f-white font-bold hj:bg-f-contrast hover:hj:bg-f-contrast/80': time === selectedTime,
              'text-f-tertiary/50 hj:text-f-white/50': time !== selectedTime,
            }"
            @click="handleTime(time)">
            {{ time }}
          </div>
        </TimeCarousel>
        <Carousel
          v-show="isSmallMode || (isLargeMode && isWidget && isMobile)"
          :value="times"
          :numVisible="2"
          :numScroll="1"
          class="b-months">
          <template #item="slotProps">
            <div
              class="text-center py-1.5 px-6 rounded-lg hover:text-f-tertiary hover:hj:text-f-white hover:bg-f-tertiary/[.07] hover:hj:bg-f-white/10 cursor-pointer"
              :class="{
                'bg-f-secondary text-f-white font-bold hj:bg-f-contrast hover:hj:bg-f-contrast/80':
                  slotProps.data === selectedTime,
                'text-f-tertiary/50 hj:text-f-white/50': slotProps.data !== selectedTime,
              }"
              @click="handleTime(slotProps.data)">
              {{ slotProps.data }}
            </div>
          </template>
        </Carousel>
      </div>
    </div>
    <div v-if="mode === modes.flexible.value">
      <div class="flex items-start justify-center mb-6">
        <div
          class="rounded-lg mr-2 cursor-pointer text-f-bg bo:text-f-tertiary hj:text-f-white"
          :class="{
            'bg-f-tertiary/[.07] hj:bg-f-tertiary border border-f-bg/60 hj:border-white/50 hover:border-f-bg hover:hj:bg-f-white/10':
              flexible.day !== 'weekday',
            'bg-f-tertiary hj:bg-f-contrast border border-f-tertiary hj:border-f-contrast !text-f-white':
              flexible.day === 'weekday',
            'py-5 px-5': isSmallMode,
            'py-10 px-12': isLargeMode,
          }"
          @click="handleDay('weekday')">
          <p class="text-lg">Weekday</p>
        </div>
        <div
          class="rounded-lg py-10 px-12 ml-2 cursor-pointer text-f-bg bo:text-f-tertiary hj:text-f-white"
          :class="{
            'bg-f-tertiary/[.07] hj:bg-f-tertiary border border-f-bg/60 hj:border-white/50 hover:border-f-bg hover:hj:bg-f-white/10':
              flexible.day !== 'weekend',
            'bg-f-tertiary hj:bg-f-contrast border border-f-tertiary hj:border-f-contrast !text-f-white':
              flexible.day === 'weekend',
            'py-5 px-5': isSmallMode,
            'py-10 px-12': isLargeMode,
          }"
          @click="handleDay('weekend')">
          <p class="text-lg">Weekend</p>
        </div>
      </div>
      <Carousel :value="months" :numVisible="isLargeMode ? 3 : 2" :numScroll="1" class="b-months">
        <template #item="slotProps">
          <div
            class="rounded-lg py-2 px-0 mx-2 cursor-pointer text-f-bg bo:text-f-tertiary hj:text-f-white text-center"
            :class="{
              'bg-f-tertiary/[.07] hj:bg-f-tertiary border border-f-bg/60 hj:border-white/50 hover:border-f-bg hover:hj:bg-f-white/10':
                flexible.month.toFormat('yyyy-MM-dd') !== slotProps.data.toFormat('yyyy-MM-dd'),
              'bg-f-tertiary hj:bg-f-contrast hj:border-f-contrast border border-f-tertiary !text-f-white hover:border-f-secondary':
                flexible.month.toFormat('yyyy-MM-dd') === slotProps.data.toFormat('yyyy-MM-dd'),
            }"
            @click="handleMonth(slotProps.data)">
            <img
              v-if="darkMode || $page.props.brand.code === 'hj'"
              :src="asset('assets/calendar.svg')"
              alt="calendar icon"
              class="inline-block mb-2.5 w-5" />
            <img
              v-else-if="!darkMode && flexible.month.toFormat('yyyy-MM-dd') === slotProps.data.toFormat('yyyy-MM-dd')"
              :src="asset('assets/calendar.svg')"
              alt="calendar icon"
              class="inline-block mb-2.5 w-5" />
            <img v-else :src="asset('assets/calendar-dark.svg')" alt="calendar icon" class="inline-block mb-2.5" />
            <p v-if="isSmallMode" class="text-lg mb-2">{{ slotProps.data.toFormat("LLL") }}</p>
            <p v-if="isLargeMode" class="text-lg mb-2">{{ slotProps.data.toFormat("LLLL") }}</p>
            <p class="">{{ slotProps.data.toFormat("yyyy") }}</p>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon"
import Carousel from "primevue/carousel"
import Datepicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import TimeCarousel from "./TimeCarousel.vue"

export default {
  name: "DateTimePicker",
  components: {
    TimeCarousel,
    Carousel,
    Datepicker,
  },
  emits: ["dateSelected", "timeSelected", "daySelected", "monthSelected", "modeSelected", "close-picker"],
  props: {
    modelValue: String,
    blockedDates: Array,
    openTimes: Object,
    dateFrom: String,
    dateTo: String,
    startDate: String,
    showTime: {
      type: Boolean,
      default: true,
    },
    showFlexible: {
      type: Boolean,
      default: true,
    },
    inputMode: {
      type: Boolean,
      default: false,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
    calendarMode: {
      type: String,
    },
  },
  data() {
    return {
      modes: {
        date: {
          name: "date",
          value: 1,
        },
        flexible: {
          name: "flexible",
          value: 2,
        },
      },
      mode: 1,
      selectedDate: null,
      selectedTime: null,
      flexible: {
        day: "weekend",
        month: DateTime.now().set({ day: 1 }),
      },
      responsiveOptions: [
        {
          breakpoint: "640px",
          numVisible: 5,
          numScroll: 1,
        },
      ],
      timesHeight: this.timesHeightCalc(),
    }
  },
  computed: {
    notIsWidget() {
      return this.isWidget === false
    },
    isSmallMode() {
      if (this.calendarMode) {
        if (this.calendarMode === "small") {
          return true
        }
        return false
      }
      return this.isWidget
    },
    isLargeMode() {
      if (this.calendarMode) {
        if (this.calendarMode === "large") {
          return true
        }
        return false
      }
      return !this.isWidget
    },
    selectedDay() {
      if (!this.selectedDate) {
        return null
      }
      return DateTime.fromJSDate(this.selectedDate).toFormat("EEEE").toLowerCase()
    },
    minDate() {
      return DateTime.fromFormat(this.dateFrom, "yyyy-MM-dd").toJSDate()
    },
    maxDate() {
      return DateTime.fromFormat(this.dateTo, "yyyy-MM-dd").toJSDate()
    },
    startDate() {
      return this.startDate ? DateTime.fromFormat(this.startDate, "yyyy-MM-dd").toJSDate() : DateTime.now().toJSDate()
    },
    disabledDates() {
      const disabledDates = []
      if (this.blockedDates) {
        this.blockedDates.forEach((date) => {
          let tempDate = DateTime.fromFormat(date.from, "yyyy-MM-dd")
          const endDate = DateTime.fromFormat(date.to, "yyyy-MM-dd")
          while (tempDate <= endDate) {
            disabledDates.push(tempDate.toJSDate())
            tempDate = tempDate.plus({ days: 1 })
          }
        })
      }
      return disabledDates
    },
    disabledWeekdays() {
      const disabledWeekdays = []
      if (this.openTimes) {
        if (!this.openTimes.sunday.open) {
          disabledWeekdays.push(0)
        }
        if (!this.openTimes.monday.open) {
          disabledWeekdays.push(1)
        }
        if (!this.openTimes.tuesday.open) {
          disabledWeekdays.push(2)
        }
        if (!this.openTimes.wednesday.open) {
          disabledWeekdays.push(3)
        }
        if (!this.openTimes.thursday.open) {
          disabledWeekdays.push(4)
        }
        if (!this.openTimes.friday.open) {
          disabledWeekdays.push(5)
        }
        if (!this.openTimes.saturday.open) {
          disabledWeekdays.push(6)
        }
      }
      return disabledWeekdays
    },
    times() {
      let times = []
      let time = null
      let endTime = null
      if (this.openTimes) {
        if (!this.selectedDay) {
          time = DateTime.fromFormat(this.openTimes.monday.open_at, "HH:mm")
          endTime = DateTime.fromFormat(this.openTimes.monday.close_at, "HH:mm")
        } else {
          time = DateTime.fromFormat(this.openTimes[this.selectedDay].open_at, "HH:mm")
          endTime = DateTime.fromFormat(this.openTimes[this.selectedDay].close_at, "HH:mm")
        }
        times.push(time.toLocaleString(DateTime.TIME_24_SIMPLE))
        while (time.toLocaleString(DateTime.TIME_24_SIMPLE) !== endTime.toLocaleString(DateTime.TIME_24_SIMPLE)) {
          time = time.plus({ minutes: 15 })
          times.push(time.toLocaleString(DateTime.TIME_24_SIMPLE))
        }
      } else {
        let time = DateTime.now().set({ hour: 9, minute: 0 })
        let endTime = DateTime.now().set({ hour: 23, minute: 0 })
        while (time.toLocaleString(DateTime.TIME_24_SIMPLE) !== endTime.toLocaleString(DateTime.TIME_24_SIMPLE)) {
          time = time.plus({ minutes: 15 })
          times.push(time.toLocaleString(DateTime.TIME_24_SIMPLE))
        }
      }

      return times
    },
    months() {
      let months = []
      let date = DateTime.now().set({ day: 1 })
      const maxDate = DateTime.fromJSDate(this.maxDate).set({ day: 1 })
      const diff = maxDate.diff(date, "months")
      const monthsToShow = Math.floor(diff.toObject().months)

      months.push(date)
      for (let i = 0; i <= monthsToShow; i++) {
        date = date.plus({ months: 1 })
        months.push(date)
      }
      return months
    },
  },
  watch: {
    modelValue(newVal, oldVal) {
      if (newVal.length === 10) {
        this.$refs.picker.updateInternalModelValue(DateTime.fromFormat(newVal, "dd/MM/yyyy").toJSDate())
      }
    },
  },
  methods: {
    handleDate() {
      this.$emit("dateSelected", this.selectedDate)
    },
    handleTime(time) {
      this.selectedTime = time
      this.$emit("timeSelected", this.selectedTime)
    },
    handleDay(day) {
      this.flexible.day = day
      this.$emit("daySelected", this.flexible.day)
    },
    handleMonth(month) {
      this.flexible.month = month
      this.$emit("monthSelected", this.flexible.month)
    },
    handleMode(mode) {
      this.mode = mode.value
      if (mode.value === this.modes.flexible.value) {
        this.handleDay(this.flexible.day)
        this.handleMonth(this.flexible.month)
      }
      if (mode.value === this.modes.date.value) {
        this.handleDate()
        if (this.selectedTime) {
          this.handleTime(this.selectedTime)
        }
      }
      this.$emit("modeSelected", mode.name)
    },
    timesHeightCalc() {
      if (window.outerWidth < 640) {
        return "180px"
      }
      return "280px"
    },
  },
  mounted() {
    window.addEventListener("resize", () => (this.timesHeight = this.timesHeightCalc()))
    if (!this.inputMode && this.$store.state.searchParams?.when?.value) {
      this.selectedDate = DateTime.fromFormat(this.$store.state.searchParams.when.value, "yyyy-MM-dd HH:mm").toJSDate()
      this.selectedTime = DateTime.fromFormat(this.$store.state.searchParams.when.value, "yyyy-MM-dd HH:mm").toFormat(
        "HH:mm",
      )
    }
  },
}
</script>

<style>
.dp__theme_dark {
  --dp-background-color: var(--ff-color-tertiary) !important;
  --dp-text-color: #ffffff !important;
  --dp-hover-color: rgba(255, 255, 255, 0.1) !important;
  --dp-hover-text-color: #ffffff !important;
  --dp-hover-icon-color: #959595 !important;
  --dp-primary-color: var(--ff-color-contrast) !important;
  --dp-primary-text-color: #f8f5f5 !important;
  --dp-secondary-color: rgba(167, 167, 167, 0.6) !important;
  --dp-border-color: var(--ff-color-tertiary) !important;
  --dp-menu-border-color: var(--ff-color-tertiary) !important;
  --dp-border-color-hover: var(--ff-color-tertiary) !important;
  --dp-disabled-color: rgba(255, 255, 255, 0.5) !important;
  --dp-scroll-bar-background: #f3f3f3 !important;
  --dp-scroll-bar-color: #959595 !important;
  --dp-success-color: #76d275 !important;
  --dp-success-color-disabled: #a3d9b1 !important;
  --dp-icon-color: #959595 !important;
  --dp-danger-color: #ff6f60 !important;
  --dp-highlight-color: rgba(25, 118, 210, 0.1) !important;
}
.dp__theme_light {
  --dp-background-color: #fff !important;
  --dp-text-color: var(--ff-color-tertiary) !important;
  --dp-hover-color: rgba(38, 18, 84, 0.07) !important;
  --dp-hover-text-color: var(--ff-color-tertiary) !important;
  --dp-hover-icon-color: rgba(38, 18, 84, 0.07) !important;
  --dp-primary-color: var(--ff-color-tertiary) !important;
  --dp-primary-text-color: #fff !important;
  --dp-secondary-color: rgba(167, 167, 167, 0.6) !important;
  --dp-border-color: #fff !important;
  --dp-menu-border-color: #fff !important;
  --dp-border-color-hover: #fff !important;
  --dp-disabled-color: rgba(255, 255, 255, 0.5) !important;
  --dp-scroll-bar-background: #f3f3f3 !important;
  --dp-scroll-bar-color: #959595 !important;
  --dp-success-color: #76d275 !important;
  --dp-success-color-disabled: #a3d9b1 !important;
  --dp-icon-color: #959595 !important;
  --dp-danger-color: #ff6f60 !important;
  --dp-highlight-color: rgba(25, 118, 210, 0.1) !important;
}
.dp__active_date,
.dp__today,
.dp__date_hover_end:hover,
.dp__date_hover_start:hover,
.dp__date_hover:hover {
  border-radius: 50px;
}
.dp__cell_inner {
  padding: 20px;
  font-size: 14px;
}
.dp__active_date {
  font-weight: bold;
}
.dp__calendar_header {
  width: 100%;
}
.dp__calendar_header_item {
  text-align: center;
}
.dp__month_year_row {
  margin-bottom: 15px;
}
.dark .dp__month_year_col_nav .dp__inner_nav {
  border: 1px solid var(--ff-color-white-50);
  width: 33px;
  height: 33px;
}
.dark .dp__inner_nav .dp__icon {
  stroke: none;
  fill: #fff;
  width: 16px;
}
.dp__month_year_col_nav .dp__inner_nav {
  border: 1px solid var(--ff-color-bg-50);
  width: 33px;
  height: 33px;
}
.hj .dp__month_year_col_nav .dp__inner_nav {
  border: 1px solid var(--ff-color-white-50);
}
.dp__inner_nav .dp__icon {
  stroke: none;
  fill: var(--ff-color-bg);
  width: 16px;
}
.hj .dp__inner_nav .dp__icon {
  fill: var(--ff-color-white);
}

.widget .dp__cell_inner {
  padding: 16px;
}

.widget .dp__month_year_col_nav {
  margin: 0px 5px;
}
</style>
