<template>
  <div class="h-[300px]">
    <div class="p-carousel-prev mx-auto" @click="changeSlide('prev')">
      <div class="p-carousel-prev-icon"></div>
    </div>
    <div
      class="b-carousel h-[79%] flex flex-col overflow-y-scroll snap-y snap-mandatory scroll-smooth hide-scrollbar"
      ref="carousel">
      <slot />
    </div>
    <div class="p-carousel-next mx-auto" @click="changeSlide('next')">
      <div class="p-carousel-prev-icon"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeCarousel",
  props: {
    items: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      position: 0,
    }
  },
  methods: {
    changeSlide(dir) {
      if (dir === "prev") {
        if (this.position > 0) {
          this.position--
        }
      }
      if (dir === "next") {
        if (this.position < this.items) {
          this.position++
        }
      }
      this.$refs.carousel.scrollTo(0, 34 * this.position)
    },
  },
}
</script>

<style lang="scss" scoped>
.b-carousel > * {
  flex: 1 0 15%;
  margin: 10px 0;
  scroll-snap-align: start;
}
</style>
