<template>
  <div>
    <div
      ref="dateTimeField"
      class="relative bo:input-light hj:input-dark w-full cursor-pointer"
      :class="{ '!border-f-status-red': ourError, '!border-f-bg hj:!border-f-white': focus }">
      <div v-if="type === 'date'" class="w-full h-full absolute top-0 left-0" @click="onDateTimeFieldClick" />
      <p
        class="transition-all text-f-bg bo:text-f-tertiary hj:text-f-white absolute left-3 pointer-events-none"
        :class="{ 'text-xs top-2': smallPlaceHolder, 'absolute-center-y': !smallPlaceHolder }">
        {{ placeholder }} {{ required ? "*" : "" }}
      </p>
      <input
        ref="input"
        class="bg-transparent border-transparent focus:border-transparent focus:ring-0 p-0 text-md w-full"
        :type="ourType"
        :step="step"
        :autocomplete="autocomplete"
        :value="modelValue"
        :required="required"
        @input="onInput($event)"
        @focus="onFocus"
        @blur="onBlur"
        :maxlength="maxInputLength"
        :min="minNum"
        :max="maxNum" />
      <div
        v-show="type === 'date' && calendarConfig.showDatePicker"
        ref="dateTimeDropdown"
        class="h-auto w-auto absolute top-full absolute-center-x left-0 mt-3 bg-white hj:bg-f-tertiary border border-f-bg/60 hj:border-white/50 rounded-2xl py-4 px-6 z-50">
        <DateTimePicker
          :date-from="calendarConfig.minDate"
          :date-to="calendarConfig.maxDate"
          :show-time="showTime"
          :show-flexible="false"
          :start-date="calendarConfig.startDate"
          @date-selected="setDate"
          @time-selected="setTime"
          @close-picker="calendarConfig.showDatePicker = false"
          input-mode
          v-model="date" />
      </div>
      <p v-if="showDateFormatHint" class="text-f-bg/50 bo:text-f-tertiary/50 hj:text-f-white/50 absolute top-5 left-3">
        dd/mm/yyyy
      </p>
    </div>
    <p v-if="ourError" class="text-sm text-f-status-red mb-4 mt-2">{{ ourError }}</p>
  </div>
</template>

<script>
import DateTimePicker from "../../components/DateTimePicker.vue"
import { DateTime } from "luxon"

export default {
  name: "BengieInput",
  components: { DateTimePicker },
  props: {
    modelValue: String,
    type: String,
    step: Number,
    error: String,
    required: Boolean,
    autocomplete: String,
    placeholder: String,
    minDate: String,
    maxDate: String,
    minNum: Number,
    maxNum: Number,
    showTime: {
      type: Boolean,
      default: false,
    },
    startDate: String,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      calendarConfig: {
        showDatePicker: false,
        minDate: this.minDate ?? DateTime.now().minus({ years: 80 }).toFormat("yyyy-MM-dd"),
        maxDate: this.maxDate ?? DateTime.now().toFormat("yyyy-MM-dd"),
        startDate: this.startDate ?? null,
        dateSet: false,
        timeSet: false,
      },
      focus: false,
      smallPlaceHolder: false,
      ourError: this.error,
    }
  },
  computed: {
    date() {
      if (this.type === "date") {
        return this.modelValue
      }
      return null
    },
    ourType() {
      return this.type === "date" ? "text" : this.type
    },
    readOnly() {
      return this.type === "date"
    },
    showDateFormatHint() {
      return this.type === "date" && this.smallPlaceHolder && !this.modelValue
    },
    maxInputLength() {
      return this.type === "date" ? 10 : ""
    },
  },
  methods: {
    onDateTimeFieldClick() {
      this.calendarConfig.showDatePicker = !this.calendarConfig.showDatePicker
      this.$refs.input.focus()
    },
    onInput($event) {
      this.$emit("update:modelValue", $event.target.value)
      if (this.ourError !== null) {
        this.ourError = null
      }
      this.smallPlaceHolder = true
    },
    onFocus() {
      this.focus = true
      this.smallPlaceHolder = true
      // if(this.type === 'date') {
      //   this.calendarConfig.showDatePicker = true
      // }
    },
    onBlur() {
      this.focus = false
      if (!this.modelValue) {
        this.smallPlaceHolder = false
      }
      if (this.type === "date" && this.modelValue) {
        this.validateDate()
      }
    },
    setDate(date) {
      const newDate = DateTime.fromJSDate(date).toFormat("dd/MM/yyyy")
      this.$emit("update:modelValue", newDate)
      this.smallPlaceHolder = true
      this.calendarConfig.dateSet = true
      if (this.showTime && this.calendarConfig.dateSet && this.calendarConfig.timeSet) {
        this.calendarConfig.showDatePicker = false
      } else {
        this.calendarConfig.showDatePicker = false
      }
    },
    setTime(time) {},
    closeDropdownOnOutsideClick(event) {
      if (this.type === "date") {
        for (const ref in this.$refs) {
          if (ref === null || ref === undefined) {
            return
          }
        }
        if (this.calendarConfig.showDatePicker) {
          if (
            this.$refs.dateTimeDropdown !== event.target &&
            !this.$refs.dateTimeDropdown.contains(event.target) &&
            this.$refs.dateTimeField !== event.target &&
            !this.$refs.dateTimeField.contains(event.target)
          ) {
            this.calendarConfig.showDatePicker = false
          }
        }
      }
    },
    validateDate() {
      if (!/^([0-9]{1,2}\/[0-9]{1,2}\/(?:[0-9]{2}){1,2})$/.test(this.modelValue)) {
        this.ourError = "Invalid date entered"
        return
      }

      const dateParts = this.modelValue.split("/")

      if (dateParts.length !== 3) {
        this.ourError = "Invalid date entered"
        return
      }

      let day = dateParts[0]
      let month = dateParts[1]
      let year = dateParts[2]

      if (
        day.length < 1 ||
        day.length > 2 ||
        month.length < 1 ||
        month.length > 2 ||
        year.length < 2 ||
        year.length === 3 ||
        year.length > 4
      ) {
        this.ourError = "Invalid date entered"
        return
      }
      if (day.length === 1) {
        day = "0" + day
      }
      if (month.length === 1) {
        month = "0" + month
      }
      if (year.length === 2) {
        if (parseInt(year) <= 40) {
          year = "20" + year
        } else {
          year = "19" + year
        }
      }

      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
      if (isNaN(date.getTime())) {
        this.ourError = "Invalid date entered"
        return
      } else {
      }
    },
    dateInputMask(e) {
      if (e.keyCode < 47 || e.keyCode > 57) {
        e.preventDefault()
      }
      var len = this.$refs.input.value.length
      // If we're at a particular place, let the user type the slash
      // i.e., 12/12/1212
      if (len !== 1 || len !== 3) {
        if (e.keyCode === 47) {
          e.preventDefault()
        }
      }
      // If they don't add the slash, do it for them...
      if (len === 2) {
        this.$refs.input.value += "/"
      }
      // If they don't add the slash, do it for them...
      if (len === 5) {
        this.$refs.input.value += "/"
      }
    },
  },
  mounted() {
    if (this.modelValue) {
      this.smallPlaceHolder = true
    }
    if (this.type === "date") {
      this.$refs.input.addEventListener("keypress", (e) => {
        this.dateInputMask(e)
      })
    }
  },
  created() {
    if (this.type === "date") {
      document.addEventListener("click", (e) => {
        this.closeDropdownOnOutsideClick(e)
      })
    }
  },
  updated() {
    if (this.modelValue) {
      this.smallPlaceHolder = true
    }
  },
  beforeUnmount() {
    if (this.type === "date") {
      document.removeEventListener("click", (e) => {
        this.closeDropdownOnOutsideClick(e)
      })
    }
  },
}
</script>

<style scoped></style>
